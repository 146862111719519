import { SystemRole } from '@frontend/domain/models/Roles';
import { OrganizationRoleEnum, SystemRoleEnum, } from '../objects/UserData';
export const canDeleteUser = (currentUser, userToDelete) => {
    if ((userToDelete === null || userToDelete === void 0 ? void 0 : userToDelete.systemRole) === SystemRoleEnum.SUPER_ADMIN)
        return false;
    const isOA = (userToDelete === null || userToDelete === void 0 ? void 0 : userToDelete.systemRole) === SystemRoleEnum.ORG_APPROVER;
    if (currentUser &&
        currentUser.systemRole === SystemRole.SUPER_ADMINISTRATOR) {
        return true;
    }
    if (currentUser &&
        currentUser.systemRole === SystemRole.ORGANIZATION_APPROVER &&
        userToDelete &&
        (userToDelete.systemRole === SystemRoleEnum.USER ||
            userToDelete.systemRole === SystemRoleEnum.ORG_APPROVER)) {
        return userToDelete.systemRole === SystemRoleEnum.USER;
    }
    if (currentUser &&
        currentUser.systemRole !== SystemRole.SUPER_ADMINISTRATOR &&
        currentUser.organizationRoles.some(({ role }) => role !== OrganizationRoleEnum.CLIENT_CONCIERGE) &&
        (userToDelete === null || userToDelete === void 0 ? void 0 : userToDelete.organizationRoles.some(({ role }) => role === OrganizationRoleEnum.CLIENT_CONCIERGE))) {
        return false;
    }
    if (userToDelete &&
        userToDelete.organizationRoles &&
        currentUser &&
        currentUser.organizationRoles) {
        return (currentUser.organizationRoles.some(({ role }) => role === OrganizationRoleEnum.LOCAL_ADMIN ||
            OrganizationRoleEnum.CLIENT_CONCIERGE) &&
            userToDelete.organizationRoles.length <= 1 &&
            !isOA);
    }
    else {
        return false;
    }
};
export const toSysRoleString = (role) => {
    switch (role) {
        case SystemRoleEnum.SUPER_ADMIN:
            return 'Super Admin';
        case SystemRoleEnum.ORG_APPROVER:
            return 'Org Approver';
        case SystemRoleEnum.USER:
            return 'User';
        default:
            return '';
    }
};
export const toOrgRoleString = (role) => {
    switch (role) {
        case OrganizationRoleEnum.LOCAL_ADMIN:
            return 'Local Admin';
        case OrganizationRoleEnum.CLIENT_CONCIERGE:
            return 'Client Concierge';
        case OrganizationRoleEnum.MEMBER:
            return 'Member';
        case OrganizationRoleEnum.VISITOR:
            return 'Visitor';
        default:
            return '';
    }
};
