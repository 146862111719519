var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, HStack } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useUserDirectory } from '@frontend/domain/contexts/User/UserDirectoryHook';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
import { getDefaultOrgData } from '@frontend/domain/models/Organization/OrganizationObjects';
import { AppRoutes } from '@frontend/shared/src/components/Routing/RouteConstants';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from '../../components/layouts/PageLayout';
import { OrganizationFormInputs } from './Organization/OrganizationFormInputs';
export const CreateOrganization = () => {
    const { onSelectOrganization, onUpdateOrganization, organizations } = useOrganizationSummaries();
    const { upsertOrganization, isLoading, orgTemplate } = useOrganization({
        onUpdateOrganization: onUpdateOrganization,
    });
    const { userDirectory } = useUserDirectory({
        filterByPrimaryContact: true,
    });
    const logoSmallRef = useRef(null);
    const navigate = useNavigate();
    const orgUseForm = useForm({
        values: getDefaultOrgData(),
        mode: 'onChange',
    });
    const { handleSubmit, formState: { isValid }, } = orgUseForm;
    const handleCancel = () => {
        navigate(AppRoutes.systemAdmin());
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        try {
            let blob = undefined;
            let logoName;
            if (((_a = logoSmallRef === null || logoSmallRef === void 0 ? void 0 : logoSmallRef.current) === null || _a === void 0 ? void 0 : _a.files) &&
                ((_c = (_b = logoSmallRef === null || logoSmallRef === void 0 ? void 0 : logoSmallRef.current) === null || _b === void 0 ? void 0 : _b.files) === null || _c === void 0 ? void 0 : _c.length) > 0) {
                logoName = logoSmallRef.current.files[0].name;
                blob = new File([logoSmallRef.current.files[0]], logoName, {
                    type: logoSmallRef.current.files[0].type,
                });
            }
            const createdOrg = yield upsertOrganization(data, blob, logoName);
            onSelectOrganization(createdOrg.identity);
            navigate(AppRoutes.organizationSettings(createdOrg.identity));
            //TODO add toast msg using the custom toast from domain
        }
        catch (e) {
            //TODO add toast msg using the custom toast from domain
        }
    });
    const validateOrgName = (value) => {
        const normalizedValue = value.trim().toLowerCase();
        const orgFound = organizations.find((org) => org.name.trim().toLowerCase() === normalizedValue);
        if (orgFound) {
            return 'An organization already exists with that name. Please enter a different name.';
        }
        else {
            return undefined;
        }
    };
    return (_jsx(PageLayout, { title: 'Create Organization', hideOrgsDropdown: false, hideAddOrgButton: false, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsxs(_Fragment, { children: [_jsxs(HStack, { w: '100%', justifyContent: 'end', mb: '20px', children: [_jsx(Button, { variant: variants.transparentBlueBtn, onClick: handleCancel, children: 'Cancel' }), _jsx(Button, { type: 'submit', variant: variants.blueBtn, isDisabled: !isValid || isLoading, children: 'Create organization' })] }), _jsx(OrganizationFormInputs, { organizationUseForm: orgUseForm, editing: true, logoSmallRef: logoSmallRef, validateOrgName: validateOrgName, users: userDirectory, verticalMarkets: orgTemplate === null || orgTemplate === void 0 ? void 0 : orgTemplate.verticalMarkets })] }) }) }));
};
