import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import { useManageOrganizationPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { PageHeader } from '../../sharedComponents/PageHeader';
import { ExpandableSidebar } from '../navigation/ExpandableSidebar';
export const PageLayout = ({ title, children, hideOrgsDropdown, hideAddOrgButton, }) => {
    var _a;
    const { organizations, onSelectOrganization, selectedOrganizationIdentity, isLoading, } = useOrganizationSummaries();
    // TODO: might not be needed after state management changes
    const { canAdd } = useManageOrganizationPermissions();
    const dropdownLabel = (_a = useBreakpointValue({
        base: 'Org',
        lg: 'Organization',
    })) !== null && _a !== void 0 ? _a : 'Organization';
    const showAddOrgButton = !hideAddOrgButton && canAdd ? true : false;
    return (_jsx(ExpandableSidebar, { children: _jsxs(Box, { children: [_jsx(PageHeader, { title: title !== null && title !== void 0 ? title : '', hideOrgsDropdown: hideOrgsDropdown, organizations: organizations, isLoading: isLoading, selectedOrganizationIdentity: selectedOrganizationIdentity, onSelectOrganization: onSelectOrganization, dropdownLabel: dropdownLabel, showAddOrgButton: showAddOrgButton }), _jsx(Flex, { flexGrow: 1, flexDir: 'column', overflowY: 'auto', p: 10, children: children })] }) }));
};
