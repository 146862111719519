import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, Text, VStack } from '@chakra-ui/react';
import { appColors } from '@frontend/design-system/theme/constants';
import { getAppNameAndLogo } from '../../../config/helperFunctions';
import { AppSelector } from './AppSelector';
export const OrganizationAppsSelector = ({ data, editMode, canEdit, onChange, }) => {
    const handleAppChange = (index, updatedProperties) => {
        const updatedApps = data.map((app, i) => {
            if (i === index) {
                const updatedApp = Object.assign(Object.assign({}, app), updatedProperties);
                if (updatedProperties.enabled === false) {
                    updatedApp.expiresOn = '';
                }
                return updatedApp;
            }
            return app;
        });
        onChange(updatedApps);
    };
    return (_jsxs(VStack, { alignItems: 'flex-start', gap: '5px', children: [_jsx(Text, { fontWeight: 600, fontSize: '14px', color: appColors.PRIM_BLUE, children: "Access" }), _jsx(FormControl, { children: _jsx(VStack, { gap: '15px', w: '100%', children: data.map((orgApp, i) => {
                        const { icon, name } = getAppNameAndLogo(orgApp.app, orgApp.enabled);
                        return (_jsx(AppSelector, { icon: icon, name: name, appData: orgApp, isEditable: canEdit && editMode, onToggle: (checked) => handleAppChange(i, { enabled: checked }), onDateChange: (date) => handleAppChange(i, { expiresOn: date }) }, `orgApp-${i}`));
                    }) }) })] }));
};
