var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useReports } from '../components/context/ReportsContext';
import { archiveOrgs } from '../services/api/orgDetailsAPI';
import { usePlatformUserApi } from '../services/api/platformUserApiHook';
export const deleteUserTitle = (user) => {
    if ((user === null || user === void 0 ? void 0 : user.systemRole) === 'SuperAdministrator') {
        return 'Delete Admin';
    }
    else if ((user === null || user === void 0 ? void 0 : user.systemRole) === 'OrganizationApprover') {
        return 'Delete Organization Approver';
    }
    else {
        return 'Delete User';
    }
};
export const deleteDescription = (itemToDelete, removeOrArchiveText = 'remove') => {
    return `You are about to ${removeOrArchiveText} ${itemToDelete}. Are
	you sure you want to continue?`;
};
export const useDeleteUser = () => {
    const { offboardUser } = usePlatformUserApi();
    const toast = useToast();
    const handleDeleteUser = (user, onEnd, org) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            if (user && user.userIdentity) {
                if (org && org.identity) {
                    yield offboardUser(user.userIdentity, org.identity);
                }
                else {
                    yield offboardUser(user.userIdentity);
                }
            }
            toast({
                title: `${user === null || user === void 0 ? void 0 : user.firstName} ${user === null || user === void 0 ? void 0 : user.lastName} has been successfully removed`,
                status: 'success',
                isClosable: true,
            });
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                toast({
                    title: `${(_a = e.response) === null || _a === void 0 ? void 0 : _a.data}`,
                    status: 'error',
                    duration: 10000,
                    isClosable: true,
                });
            }
            else {
                toast({
                    title: `Unable to remove ${user === null || user === void 0 ? void 0 : user.firstName} ${user === null || user === void 0 ? void 0 : user.lastName}.`,
                    status: 'error',
                    isClosable: true,
                });
            }
        }
        finally {
            onEnd();
        }
    });
    return handleDeleteUser;
};
export const useArchiveOrg = () => {
    const toast = useToast();
    const handleArchiveOrg = (org, onEnd) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (org)
                yield archiveOrgs({
                    organizationIds: [org === null || org === void 0 ? void 0 : org.identity],
                    deactivate: true,
                });
            toast({
                title: `${org === null || org === void 0 ? void 0 : org.name} has been successfully removed`,
                status: 'success',
                isClosable: true,
            });
        }
        catch (e) {
            toast({
                title: `Unable to remove ${org === null || org === void 0 ? void 0 : org.name}.`,
                status: 'error',
                isClosable: true,
            });
        }
        finally {
            onEnd();
        }
    });
    return handleArchiveOrg;
};
export const useDeleteReport = () => {
    const { removeReport } = useReports();
    const toast = useToast();
    const handleDeleteReport = (report, onEnd) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (report)
                yield removeReport(report.reportId);
            toast({
                title: `${report === null || report === void 0 ? void 0 : report.reportName} has been successfully removed`,
                status: 'success',
                isClosable: true,
            });
        }
        catch (e) {
            toast({
                title: `Unable to remove ${report === null || report === void 0 ? void 0 : report.reportName}.`,
                status: 'error',
                isClosable: true,
            });
        }
        finally {
            onEnd();
        }
    });
    return handleDeleteReport;
};
