var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useToast, VStack, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { appColors } from '../../../config/constants';
import { SystemRoleEnum, } from '../../../objects/UserData';
import { usePlatformUserApi } from '../../../services/api/platformUserApiHook';
import { getUserByEmail } from '../../../services/api/profilePageAPI';
import { AddOrgToUser } from './AddOrgToUser';
export const AddUserModal = ({ isOpen, onClose, availableOrgs, originalUser, getOrgUsersList, currentUser, }) => {
    var _a, _b;
    const [newEmail, setNewEmail] = useState();
    const [userToUpdate, setUserToUpdate] = useState();
    const [showFirstPage, setShowFirstPage] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [orgsAdjustments, setOrgsAdjustments] = useState([]);
    const [originalUserOrgRoles, setOriginalUserOrgRoles] = useState((_a = originalUser === null || originalUser === void 0 ? void 0 : originalUser.organizationRoles) !== null && _a !== void 0 ? _a : []);
    const [facilityRolesAdjustments, setFacilityRolesAdjustments] = useState([]);
    const [currentUserFacilityRoles, setCurrentUserFacilityRoles] = useState((_b = originalUser === null || originalUser === void 0 ? void 0 : originalUser.facilityRoles) !== null && _b !== void 0 ? _b : []);
    const [orgUserApps, setOrgUserApps] = useState([]);
    const { register, handleSubmit, setValue, watch } = useForm();
    const toast = useToast();
    const { sendInvite, configureUser } = usePlatformUserApi();
    const firstNameRegister = register(FIRST_NAME_KEY);
    const lastNameRegister = register(LAST_NAME_KEY);
    const jobTitleRegister = register(JOB_TITLE_KEY);
    const emailRegister = register(EMAIL_KEY, { pattern: /^\S+@\S+$/i });
    const phoneNumberRegister = register(PHONE_NUMBER_KEY);
    const emailWatch = watch(EMAIL_KEY);
    useEffect(() => {
        if (originalUser) {
            setUserToUpdate(originalUser);
            setShowFirstPage(false);
        }
        else {
            setUserToUpdate(undefined);
            setShowFirstPage(true);
        }
    }, [originalUser]);
    useEffect(() => {
        if (userToUpdate) {
            const { firstName, lastName, jobTitle, email, phoneNumber, organizationRoles, facilityRoles, apps, } = userToUpdate;
            setValue(FIRST_NAME_KEY, firstName !== null && firstName !== void 0 ? firstName : '');
            setValue(LAST_NAME_KEY, lastName !== null && lastName !== void 0 ? lastName : '');
            setValue(JOB_TITLE_KEY, jobTitle !== null && jobTitle !== void 0 ? jobTitle : '');
            setValue(EMAIL_KEY, email !== null && email !== void 0 ? email : '');
            setValue(PHONE_NUMBER_KEY, phoneNumber !== null && phoneNumber !== void 0 ? phoneNumber : '');
            // TODO: get this to initialize in state
            if (organizationRoles) {
                setOriginalUserOrgRoles(organizationRoles.map((orgRole) => {
                    return {
                        organizationIdentity: orgRole.organizationIdentity,
                        role: orgRole.role,
                        unassign: false,
                    };
                }));
            }
            // TODO: get this to initialize in state
            if (facilityRoles) {
                setCurrentUserFacilityRoles(facilityRoles);
            }
            if (apps) {
                setOrgUserApps(apps.map((userApp) => ({
                    organizationId: userApp.organizationId,
                    organizationIdentity: userApp.organizationIdentity,
                    app: userApp.app,
                    unassign: false,
                    expiresOn: userApp.expiresOn,
                })));
            }
        }
    }, [userToUpdate, userToUpdate === null || userToUpdate === void 0 ? void 0 : userToUpdate.organizationRoles, setValue]);
    const updateOriginalUserOrgRolesOnChange = (updatedOrgRole) => {
        const newOrgAdjustments = orgsAdjustments.filter((orgRole) => orgRole.organizationIdentity !==
            updatedOrgRole.organizationIdentity);
        newOrgAdjustments.push(updatedOrgRole);
        const newCurrentUserOrgRoles = originalUserOrgRoles.filter((orgRole) => orgRole.organizationIdentity !==
            updatedOrgRole.organizationIdentity);
        newCurrentUserOrgRoles.push(updatedOrgRole);
        setOrgsAdjustments(newOrgAdjustments);
        setOriginalUserOrgRoles(newCurrentUserOrgRoles);
    };
    const updateCurrentUserFacilitiesRolesOnChange = (updatedFacilityRole) => {
        const newFacilityRolesAdjustments = facilityRolesAdjustments.filter((facilityRole) => facilityRole.facilityIdentity !==
            updatedFacilityRole.facilityIdentity);
        newFacilityRolesAdjustments.push(updatedFacilityRole);
        const newCurrentUserFacilityRoles = currentUserFacilityRoles.filter((facilityRole) => facilityRole.facilityIdentity !==
            updatedFacilityRole.facilityIdentity);
        newCurrentUserFacilityRoles.push(updatedFacilityRole);
        setFacilityRolesAdjustments(newFacilityRolesAdjustments);
        setCurrentUserFacilityRoles(newCurrentUserFacilityRoles);
    };
    const userExists = () => __awaiter(void 0, void 0, void 0, function* () {
        if (newEmail) {
            setIsSubmitting(true);
            try {
                const user = yield getUserByEmail(newEmail);
                setUserToUpdate(user);
            }
            catch (_c) {
                setValue(EMAIL_KEY, newEmail);
                setUserToUpdate(undefined);
            }
            setShowFirstPage(false);
            setIsSubmitting(false);
        }
    });
    const renderInput = (label, register, inputType, disabled) => {
        return (_jsx(Input, Object.assign({ type: inputType ? inputType : 'text', placeholder: label }, register, { isDisabled: disabled })));
    };
    const renderFirstPageBody = () => {
        return (_jsxs(FormControl, { children: [_jsxs(HStack, { children: [_jsx(FormLabel, { mr: '3px', children: "Email" }), _jsx("p", { style: {
                                color: 'red',
                                marginLeft: '0',
                                marginBottom: '9px',
                            }, children: "*" })] }), _jsx(Input, { type: 'email', placeholder: 'Email', isDisabled: !showFirstPage, defaultValue: !showFirstPage ? newEmail : '', onChange: (e) => {
                        setNewEmail(e.target.value);
                    } })] }));
    };
    const renderFirstPageFooter = () => {
        return (_jsx(Button, { type: 'button', w: '100%', variant: variants.gradientBtn, isDisabled: !emailIsValid(newEmail), onClick: () => {
                userExists();
                // setOnUserPage(true);
            }, isLoading: isSubmitting, children: "Next" }));
    };
    const renderUserPageBody = () => {
        return (_jsxs(Stack, { py: 2, spacing: 5, children: [_jsxs(HStack, { spacing: '20px', children: [renderInput('First Name', firstNameRegister), renderInput('Last Name', lastNameRegister)] }), _jsxs(HStack, { spacing: '20px', children: [renderInput('Job title', jobTitleRegister), renderInput('Phone number', phoneNumberRegister, 'tel')] }), emailWatch !== newEmail && (_jsx("div", { children: renderInput('Email', emailRegister, 'email', !!originalUser) })), _jsx(VStack, { alignItems: 'flex-start', bg: 'white', border: `1px solid ${appColors.BORDER_SEC_LIGHT_GRAY}`, justifyContent: 'space-between', p: '10px', children: _jsx(AddOrgToUser, { currentUser: currentUser, availableOrgs: availableOrgs, originalUserOrgRoles: originalUserOrgRoles, updateOrgRoles: updateOriginalUserOrgRolesOnChange, originalUserFacilityRoles: currentUserFacilityRoles, updateFacRoles: updateCurrentUserFacilitiesRolesOnChange, orgUserApps: orgUserApps, setOrgUserApps: setOrgUserApps }) })] }));
    };
    const renderUserPageFooter = () => {
        return (_jsx(Button, { type: 'submit', w: '100%', variant: variants.gradientBtn, isLoading: isSubmitting, children: userToUpdate ? 'save' : 'add' }));
    };
    const onEnd = () => {
        onClose();
        setValue(FIRST_NAME_KEY, '');
        setValue(LAST_NAME_KEY, '');
        setValue(JOB_TITLE_KEY, '');
        setValue(EMAIL_KEY, '');
        setValue(PHONE_NUMBER_KEY, '');
        setNewEmail(undefined);
        setUserToUpdate(undefined);
        setShowFirstPage(true);
        setIsSubmitting(false);
        setOrgsAdjustments([]);
        setFacilityRolesAdjustments([]);
        setOrgUserApps([]);
        setCurrentUserFacilityRoles([]);
        setOriginalUserOrgRoles([]);
        if (getOrgUsersList) {
            getOrgUsersList();
        }
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSubmitting(true);
        const orgRoles = orgsAdjustments.length > 0 ? orgsAdjustments : undefined;
        const facilityRoles = facilityRolesAdjustments.length > 0
            ? facilityRolesAdjustments
            : undefined;
        if (userToUpdate) {
            try {
                yield configureUser({
                    userId: userToUpdate.userIdentity,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phoneNumber: data.phoneNumber,
                    jobTitle: data.jobTitle,
                    emailAddress: data.email,
                    systemRole: userToUpdate.systemRole,
                    organizationRoles: orgRoles,
                    facilityRoles: facilityRoles,
                    apps: orgUserApps,
                });
                toast({
                    title: `User with email ${data.email} updated`,
                    status: 'success',
                    isClosable: true,
                });
                onEnd();
            }
            catch (error) {
                setIsSubmitting(false);
                toast({
                    title: 'Error updating user',
                    description: 'Please check that all fields are entered and try again.',
                    status: 'error',
                    isClosable: true,
                });
            }
        }
        else {
            try {
                yield sendInvite({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phoneNumber: data.phoneNumber,
                    jobTitle: data.jobTitle,
                    emailAddress: data.email,
                    systemRole: SystemRoleEnum.USER,
                    organizationRoles: orgRoles,
                    facilityRoles: facilityRoles,
                    apps: orgUserApps.filter((app) => app.unassign === false),
                });
                toast({
                    title: `User with email ${data.email} created`,
                    status: 'success',
                    isClosable: true,
                });
                onEnd();
            }
            catch (error) {
                // TODO: pattern for descriptive error parsing and messages if error < 500
                setIsSubmitting(false);
                toast({
                    title: 'Error adding user',
                    description: 'Please check that email is not already in use and all fields are entered and try again.',
                    status: 'error',
                    isClosable: true,
                });
            }
        }
    });
    return (_jsxs(Modal, { isOpen: isOpen, onClose: onEnd, scrollBehavior: 'outside', closeOnOverlayClick: false, children: [_jsx(ModalOverlay, {}), _jsx(ModalContent, { minW: 741, borderRadius: 20, filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25))', fontWeight: 300, boxSizing: 'border-box', p: 4, children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs(ModalHeader, { color: appColors.TEXT_COLOR, fontSize: 36, fontWeight: 400, children: [userToUpdate ? 'Edit' : 'New', " User"] }), _jsx(ModalCloseButton, { mt: 7, mr: 7, size: 'lg' }), _jsx(ModalBody, { children: showFirstPage
                                ? renderFirstPageBody()
                                : renderUserPageBody() }), _jsx(ModalFooter, { pt: 5, children: showFirstPage
                                ? renderFirstPageFooter()
                                : renderUserPageFooter() })] }) })] }));
};
const emailIsValid = (email) => {
    return email && email !== '' && /^\S+@\S+$/i.test(email);
};
const FIRST_NAME_KEY = 'firstName';
const LAST_NAME_KEY = 'lastName';
const PHONE_NUMBER_KEY = 'phoneNumber';
const JOB_TITLE_KEY = 'jobTitle';
const EMAIL_KEY = 'email';
