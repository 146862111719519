import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Center, Image } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import React, { useEffect, useState } from 'react';
import { MdModeEditOutline } from 'react-icons/md';
import cameraIcon from '../../../images/CameraIcon.svg';
export const OrganizationLogoUpload = ({ initialImageUrl, initialImageName, imageRef, onLogoChange, editMode, acceptedFileTypes, }) => {
    // the useRef hook does not trigger re-renders, so we set these state
    // variables when the selected file changes
    const [image, setImageState] = useState(undefined);
    const [hasLogo, setHasLogo] = useState(false);
    useEffect(() => {
        var _a, _b, _c;
        if ((initialImageName && initialImageName !== '') ||
            (((_a = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _a === void 0 ? void 0 : _a.files) &&
                ((_b = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _b === void 0 ? void 0 : _b.files[0]) &&
                ((_c = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _c === void 0 ? void 0 : _c.files[0].name))) {
            setHasLogo(true);
        }
        else {
            setHasLogo(false);
        }
    }, [initialImageName, image]);
    useEffect(() => {
        if (image) {
            onLogoChange(URL.createObjectURL(image));
        }
    }, [image]);
    const setImage = () => {
        var _a, _b;
        const input = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current;
        if (input) {
            const file = (_b = (_a = input.files) === null || _a === void 0 ? void 0 : _a.item(0)) !== null && _b !== void 0 ? _b : undefined;
            if (file) {
                setImageState(file);
                onLogoChange(URL.createObjectURL(file));
            }
        }
    };
    return (_jsxs(Box, { aspectRatio: 1, borderRadius: '8px', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)', boxSizing: 'border-box', p: '19px', alignContent: 'center', position: 'relative', children: [_jsx(Box, { flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center', h: hasLogo ? 'auto' : '30px', children: _jsx(Image, { src: hasLogo ? initialImageUrl : cameraIcon, w: '100%', h: '100%', fit: 'contain', alt: 'Organization logo' }) }), editMode && (_jsx(Center, { children: _jsx(Button, { onClick: () => {
                        var _a;
                        (_a = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _a === void 0 ? void 0 : _a.click();
                    }, variant: variants.transparentBlueBtn, leftIcon: _jsx(MdModeEditOutline, {}), "aria-label": 'edit logo', position: 'absolute', bottom: '25px', children: "Edit" }) })), _jsx("input", { type: 'file', style: { display: 'none' }, accept: acceptedFileTypes, ref: imageRef, onChange: setImage })] }));
};
