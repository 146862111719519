import { useEffect, useState } from 'react';
import { useUserService } from '../../services/UserService';
import { useDependencies } from '../Dependencies/DependenciesContext';
export const useUserDirectory = ({ organizationIdentity, filterByPrimaryContact, }) => {
    const { platformApi } = useDependencies();
    const { user: { fetchList }, } = useUserService({ platformApi });
    // initialize with true due to fetch in useEffect
    const [isLoading, setIsLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        fetchList(organizationIdentity, undefined, filterByPrimaryContact)
            .then(setUserList)
            .finally(() => setIsLoading(false));
    }, []); // ensures only fetches list once on initialization
    return {
        isLoading,
        userDirectory: userList,
    };
};
