import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, FormLabel, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { OrganizationLogoUpload } from './OrganizationLogoUpload';
export const OrganizationLogoInput = ({ logoUrl, logoName, logoRef, imageError, onLogoChange, editMode, acceptedFileTypes, }) => {
    return (_jsxs(Stack, { spacing: 6, children: [_jsxs(Box, { children: [editMode && (_jsx(FormControl, { children: _jsx(FormLabel, { pl: 0, children: "Organization logo" }) })), _jsx(OrganizationLogoUpload, { initialImageUrl: logoUrl, initialImageName: logoName, imageRef: logoRef, acceptedFileTypes: acceptedFileTypes, onLogoChange: onLogoChange, editMode: editMode })] }), imageError && (_jsx(Text, { style: {
                    color: 'red',
                    fontSize: 'sm',
                }, children: imageError }))] }));
};
