var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const baseReportUrlMsiqApi = '/Report';
const platformApiPath = '/Organization';
export default class OrgService {
    constructor(api, platformApi) {
        this.api = api;
        this.platformApi = platformApi;
    }
    getOrg() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.get('/organizations');
        });
    }
    getPlatformSitesByOrgId(orgIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            const orgResponse = yield this.platformApi
                .get(`/api/v1${platformApiPath}/view/${orgIdentity}`)
                .then((orgResponse) => this.getPlatformSitesFromOrganizationResponse(orgResponse));
            return orgResponse;
        });
    }
    getPlatformSitesFromOrganizationResponse(response) {
        var _a, _b;
        if (!response.organizationIdentity) {
            console.error('Organization identity is missing', response);
            throw new Error('Organization identity is missing');
        }
        const sites = (_b = (_a = response.sites) === null || _a === void 0 ? void 0 : _a.map((site) => {
            var _a, _b, _c, _d, _e, _f;
            if (!site.siteId) {
                console.error('Site id is missing', site);
                throw new Error('Site id is missing');
            }
            if (!site.siteIdentity) {
                console.error('Site identity is missing', site);
                throw new Error('Site identity is missing');
            }
            if (!site.name) {
                console.error('Site name is missing', site);
                throw new Error('Site name is missing');
            }
            return {
                id: site.siteId,
                identity: site.siteIdentity,
                name: site.name,
                address: {
                    address1: (_a = site.address1) !== null && _a !== void 0 ? _a : '',
                    address2: (_b = site.address2) !== null && _b !== void 0 ? _b : '',
                    city: (_c = site.city) !== null && _c !== void 0 ? _c : '',
                    state: (_d = site.stateOrProvince) !== null && _d !== void 0 ? _d : '',
                    zip: (_e = site.postalCode) !== null && _e !== void 0 ? _e : '',
                },
                facilities: (_f = site.facilities) !== null && _f !== void 0 ? _f : [],
            };
        })) !== null && _b !== void 0 ? _b : [];
        return sites;
    }
    getAllOrgs() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.get('/organizations');
        });
    }
    getAllOrgsWithRelations(relations) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.getWithQueryParams('/organizations', {}, { relations: relations });
        });
    }
    getOrgById(oid, relations) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.getWithQueryParams(`/organizations/${oid}`, {}, { relations });
        });
    }
    getAmountOfUsersForOrg(oid) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.get(`/organizations/${oid}/users`);
        });
    }
    deleteOrgById(oid) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.delete(`/organizations/${oid}`);
        });
    }
    archiveOrgs(body) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post('/api/v1/Organization/archive', body);
        });
    }
    deleteUserFromOrg(oid, uid) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.delete(`/organizations/${oid}/users/${uid}`);
        });
    }
    updateOrg(oid, org) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`/organization/${oid}`, org);
        });
    }
    createOrg(org) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.post('/organizations', org);
        });
    }
    addSiteToOrg(identity, newSite) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`/organizations/${identity}/sites`, newSite);
        });
    }
    addContactToOrg(oid, newContact) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`/organizations/${oid}/contacts`, newContact);
        });
    }
    updateContact(cid, contact) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`/contacts/${cid}`, contact);
        });
    }
    userReports(orgIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.getWithQueryParams(`/api/v1${baseReportUrlMsiqApi}/list/${orgIdentity}`);
        });
    }
    fetchReport(reportIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.getWithQueryParams(`/api/v1${baseReportUrlMsiqApi}/view/${reportIdentity}`);
        });
    }
    assessmentRefresh(orgIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post(`/api/v1/Assessment/refresh/${orgIdentity}`);
        });
    }
    registerReport(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post(`/api/v1${baseReportUrlMsiqApi}/register`, data);
        });
    }
    removeReport(reportId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post(`/api/v1${baseReportUrlMsiqApi}/remove/${reportId}`);
        });
    }
}
