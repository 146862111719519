import { IAppNames } from '../App';
export const getDefaultOrgData = (org) => {
    var _a, _b;
    const appNamesOrder = [
        IAppNames.PLANNER,
        IAppNames.FCA,
        IAppNames.REPORTS,
        IAppNames.PIQ,
    ];
    return {
        nameOfOrg: (org === null || org === void 0 ? void 0 : org.name) || '',
        logoUrl: ((_a = org === null || org === void 0 ? void 0 : org.logo) === null || _a === void 0 ? void 0 : _a.url) || undefined,
        logoName: ((_b = org === null || org === void 0 ? void 0 : org.logo) === null || _b === void 0 ? void 0 : _b.name) || undefined,
        businessUnit: (org === null || org === void 0 ? void 0 : org.businessUnit) || '',
        verticalMarket: (org === null || org === void 0 ? void 0 : org.verticalMarket) || '',
        companyWebsite: (org === null || org === void 0 ? void 0 : org.companyWebsite) || '',
        primaryContactId: (org === null || org === void 0 ? void 0 : org.primaryContact.identity) || undefined,
        apps: org
            ? org === null || org === void 0 ? void 0 : org.apps.map((orgApp) => ({
                app: orgApp.app,
                enabled: orgApp.enabled,
                expiresOn: orgApp.expiresOn || '',
            })).sort((a, b) => appNamesOrder.indexOf(a.app) -
                appNamesOrder.indexOf(b.app))
            : [
                { app: IAppNames.PLANNER, enabled: false, expiresOn: '' },
                { app: IAppNames.FCA, enabled: false, expiresOn: '' },
                { app: IAppNames.REPORTS, enabled: false, expiresOn: '' },
                { app: IAppNames.PIQ, enabled: false, expiresOn: '' },
            ],
    };
};
