import { IAppNames } from '@frontend/domain/models/App';
import cpIcon from '../images/CP_nav_icon.svg';
import cpIconDisabled from '../images/CP_nav_icon_disabled.svg';
import fcaIcon from '../images/FCA_nav_icon.svg';
import fcaIconDisabled from '../images/FCA_nav_icon_disabled.svg';
import portfolioIQIcon from '../images/PIQ_nav_icon.svg';
import portfolioIQIconDisabled from '../images/PIQ_nav_icon_disabled.svg';
import reportsIcon from '../images/Reports_nav_icon.svg';
import reportsIconDisabled from '../images/Reports_nav_icon_disabled.svg';
export const getAppNameAndLogo = (app, enabled = true) => {
    let icon = undefined;
    let name = undefined;
    switch (app) {
        case IAppNames.REPORTS:
            icon = enabled ? reportsIcon : reportsIconDisabled;
            name = 'mySiteIQ';
            break;
        case IAppNames.FCA:
            icon = enabled ? fcaIcon : fcaIconDisabled;
            name = 'FCA';
            break;
        case IAppNames.PLANNER:
            icon = enabled ? cpIcon : cpIconDisabled;
            name = 'Capital Planner';
            break;
        case IAppNames.PIQ:
            icon = enabled ? portfolioIQIcon : portfolioIQIconDisabled;
            name = 'myOpportunities';
            break;
    }
    return { icon, name };
};
