var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { DeleteModal } from '@frontend/design-system/components/DeleteModal/DeleteModal';
import { appColors } from '@frontend/design-system/theme/constants';
import { variants } from '@frontend/design-system/theme/theme';
import { getDefaultOrgData } from '@frontend/domain/models/Organization/OrganizationObjects';
import { AppRoutes } from '@frontend/shared/src/components/Routing/RouteConstants';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoMdArchive } from 'react-icons/io';
import { MdModeEditOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { OrganizationFormInputs } from './OrganizationFormInputs';
export const OrganizationTab = ({ isClientConciergePlus, canEditBusinessUnit, canEditOrgAccess, isAdmin, organization, organizations, upsertOrganization, archiveOrganization, onSelectOrganization, users, isLoading, orgTemplate, }) => {
    var _a;
    const [isEditing, setIsEditing] = useState(false);
    const logoSmallRef = useRef(null);
    const navigate = useNavigate();
    const archiveOrganizationDisclosure = useDisclosure();
    const initialOrgData = getDefaultOrgData(organization);
    const orgUseForm = useForm({
        values: initialOrgData,
        mode: 'onChange',
    });
    const { handleSubmit, formState: { isValid }, reset, } = orgUseForm;
    const handleCancel = () => {
        reset(initialOrgData);
        setIsEditing(false);
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d;
        try {
            let blob = undefined;
            let logoName = initialOrgData.logoName;
            if (((_b = logoSmallRef === null || logoSmallRef === void 0 ? void 0 : logoSmallRef.current) === null || _b === void 0 ? void 0 : _b.files) &&
                ((_d = (_c = logoSmallRef === null || logoSmallRef === void 0 ? void 0 : logoSmallRef.current) === null || _c === void 0 ? void 0 : _c.files) === null || _d === void 0 ? void 0 : _d.length) > 0) {
                logoName = logoSmallRef === null || logoSmallRef === void 0 ? void 0 : logoSmallRef.current.files[0].name;
                blob = new File([logoSmallRef.current.files[0]], logoName, {
                    type: logoSmallRef.current.files[0].type,
                });
            }
            yield upsertOrganization(data, blob, logoName, organization === null || organization === void 0 ? void 0 : organization.identity);
            setIsEditing(false);
            //TODO add toast msg using the custom toast from domain
        }
        catch (e) {
            //TODO add toast msg using the custom toast from domain
        }
    });
    const validateOrgName = (value) => {
        const normalizedValue = value.trim().toLowerCase();
        const orgFound = organizations.find((org) => org.name.trim().toLowerCase() === normalizedValue &&
            org.identity !== (organization === null || organization === void 0 ? void 0 : organization.identity));
        if (orgFound) {
            return 'An organization already exists with that name. Please enter a different name.';
        }
        else {
            return undefined;
        }
    };
    const onArchiveOrganization = () => __awaiter(void 0, void 0, void 0, function* () {
        if (organization) {
            archiveOrganization({
                identity: organization.identity,
                nameOfOrg: organization.name,
            });
            navigate(AppRoutes.landingPage());
        }
        onSelectOrganization(undefined);
    });
    const renderHeader = () => {
        return (_jsxs(HStack, { w: '100%', justifyContent: 'space-between', gap: 10, children: [_jsx(Text, { textColor: appColors.TEXT_COLOR, alignSelf: 'flex-start', fontWeight: 400, fontSize: { base: '24px', xl: '32px' }, children: organization === null || organization === void 0 ? void 0 : organization.name }), _jsxs(HStack, { children: [_jsx(Button, { onClick: () => {
                                setIsEditing(true);
                            }, variant: variants.editBtn, leftIcon: _jsx(MdModeEditOutline, {}), "aria-label": 'edit org', height: { base: '28px', xl: '36px' }, width: 'auto', children: 'Edit' }), isAdmin && (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: () => archiveOrganizationDisclosure.onOpen(), isDisabled: isLoading, variant: variants.transDelBtn, leftIcon: _jsx(IoMdArchive, {}), "aria-label": 'archive org', height: { base: '28px', xl: '36px' }, width: 'auto', children: 'Archive' }), _jsx(DeleteModal, { disclosure: archiveOrganizationDisclosure, onDelete: onArchiveOrganization, text: 'Once an organization is archived, no user can access the organizations reports or applications.', isArchive: true })] }))] })] }));
    };
    return (_jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsxs(_Fragment, { children: [isEditing && (_jsxs(HStack, { w: '100%', justifyContent: 'end', pb: '10px', children: [_jsx(Button, { variant: variants.transparentBlueBtn, onClick: handleCancel, height: { base: '28px', xl: '36px' }, width: 'auto', children: 'Cancel' }), _jsx(Button, { type: 'submit', variant: variants.blueBtn, isDisabled: !isValid || isLoading, height: { base: '28px', xl: '36px' }, width: 'auto', children: 'Save' })] })), _jsx(OrganizationFormInputs, { canEditVerticalMarket: isClientConciergePlus, canEditBusinessUnit: canEditBusinessUnit, canEditOrgAccess: canEditOrgAccess, organization: organization, organizationUseForm: orgUseForm, editing: isEditing, logoSmallRef: logoSmallRef, validateOrgName: validateOrgName, orgHeader: renderHeader, users: users, logoName: (_a = organization === null || organization === void 0 ? void 0 : organization.logo) === null || _a === void 0 ? void 0 : _a.name, verticalMarkets: orgTemplate.verticalMarkets })] }) }));
};
