import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormLabel, Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import { SelectInput } from '@frontend/design-system/components/DynamicInput/SelectInput';
import { TextInput } from '@frontend/design-system/components/DynamicInput/TextInput';
import { Form } from '@frontend/design-system/components/FormModal/FormModal';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { BusinessUnitOptions } from '../../../objects/UserData';
export const OrganizationInformation = ({ organization, canEditVerticalMarket, canEditBusinessUnit, control, validateOrgName, editMode, users, verticalMarkets, }) => {
    const [sitelogiqUsers, setSitelogiqUsers] = useState([]);
    useEffect(() => {
        let filteredUsers = users;
        if (organization) {
            filteredUsers = filteredUsers.filter((user) => user.identity !== organization.primaryContact.identity);
        }
        filteredUsers = filteredUsers.sort((a, b) => a.emailAddress.localeCompare(b.emailAddress));
        setSitelogiqUsers(filteredUsers);
    }, [users, organization]);
    const primaryContactOnChange = (value) => {
        let filteredUsers = users;
        if (value) {
            filteredUsers = filteredUsers.filter((user) => user.identity !== value);
        }
        filteredUsers = filteredUsers.sort((a, b) => a.emailAddress.localeCompare(b.emailAddress));
        setSitelogiqUsers(filteredUsers);
    };
    return (_jsxs(Grid, { templateColumns: {
            base: '1fr',
            lg: canEditVerticalMarket ? '1fr' : 'repeat(2, 1fr)',
        }, gap: '20px', w: '100%', children: [_jsx(Controller, { name: NAME_OF_ORG_KEY, control: control, rules: {
                    required: true,
                    validate: (value) => validateOrgName(value),
                }, render: ({ field, fieldState: { error } }) => editMode ? (_jsx(TextInput, { label: 'Organization name', editMode: editMode, inputType: 'text', data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: (value) => {
                        field.onChange(value);
                        validateOrgName(value);
                    }, constraints: { maxLength: 50 }, required: true })) : (_jsx(_Fragment, {})) }), _jsx(GridItem, { children: _jsx(Controller, { name: PRIMARY_CONTACT_ID_KEY, control: control, rules: {
                        required: true,
                    }, render: ({ field }) => {
                        var _a, _b;
                        const primaryContact = users.find((user) => user.identity === field.value);
                        return !editMode ? (_jsxs(VStack, { align: 'flex-start', children: [_jsx(FormLabel, { paddingLeft: '0px', htmlFor: 'Primary contact', children: 'Primary contact' }), _jsx(Text, { fontSize: '14px', children: `${organization === null || organization === void 0 ? void 0 : organization.primaryContact.firstName} ${organization === null || organization === void 0 ? void 0 : organization.primaryContact.lastName}` }), _jsx(Text, { fontSize: '14px', children: organization === null || organization === void 0 ? void 0 : organization.primaryContact.email })] })) : (_jsx(Form.SearchableDropdown, { isClearable: false, label: 'Primary contact', isDisabled: organization && !canEditBusinessUnit, value: organization && !canEditBusinessUnit
                                ? {
                                    label: (_a = organization === null || organization === void 0 ? void 0 : organization.primaryContact) === null || _a === void 0 ? void 0 : _a.email,
                                    value: (_b = organization === null || organization === void 0 ? void 0 : organization.primaryContact) === null || _b === void 0 ? void 0 : _b.identity,
                                }
                                : primaryContact && {
                                    label: primaryContact === null || primaryContact === void 0 ? void 0 : primaryContact.emailAddress,
                                    value: primaryContact === null || primaryContact === void 0 ? void 0 : primaryContact.identity,
                                }, onChange: (value) => {
                                primaryContactOnChange(value === null || value === void 0 ? void 0 : value.value);
                                field.onChange((value === null || value === void 0 ? void 0 : value.value) || undefined);
                            }, selectFrom: sitelogiqUsers.map((user) => ({
                                label: user.emailAddress,
                                value: user.identity,
                            })), isRequired: true }));
                    } }) }), _jsx(GridItem, { children: _jsx(Controller, { name: COMPANY_WEBSITE_KEY, control: control, rules: { required: true }, render: ({ field, fieldState: { error } }) => (_jsx(TextInput, { label: 'Organization website', editMode: editMode, inputType: 'text', data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: field.onChange, constraints: { maxLength: 50 }, required: editMode })) }) }), _jsx(GridItem, { children: _jsx(Controller, { name: VERTICAL_MARKET_KEY, control: control, rules: { required: true }, render: ({ field }) => {
                        return canEditVerticalMarket ? (_jsx(SelectInput, { label: 'Vertical Market', editable: editMode, isDisabled: organization ? true : false, data: field.value, choices: verticalMarkets.map((option) => ({
                                label: option,
                                value: option,
                            })), placeholder: 'Select', onInputChange: field.onChange, required: editMode })) : (_jsx(_Fragment, {}));
                    } }) }), _jsx(GridItem, { children: _jsx(Controller, { name: BUSINESS_UNIT_KEY, control: control, rules: { required: true }, render: ({ field }) => {
                        return canEditVerticalMarket ? (_jsx(SelectInput, { label: 'Business Unit', editable: editMode, isDisabled: organization && !canEditBusinessUnit, data: field.value, choices: BusinessUnitOptions.map((option) => ({
                                label: option,
                                value: option,
                            })), placeholder: 'Select', onInputChange: field.onChange, required: editMode })) : (_jsx(_Fragment, {}));
                    } }) })] }));
};
const NAME_OF_ORG_KEY = 'nameOfOrg';
const BUSINESS_UNIT_KEY = 'businessUnit';
const VERTICAL_MARKET_KEY = 'verticalMarket';
const COMPANY_WEBSITE_KEY = 'companyWebsite';
const PRIMARY_CONTACT_ID_KEY = 'primaryContactId';
