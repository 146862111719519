export const appColors = {
    PRIM_BLUE: '#003B5D',
    SEC_ORANGE: '#F6862A',
    TEXT_COLOR: '#2E3532',
    BACKGROUND_LIGHT_GRAY: '#FBFBFB',
    DELETE_ERROR: '#DA1100',
    GREEN: '#15803D',
    LIGHT_GRAY_1: '#F2F3F4',
    BORDER_SEC_LIGHT_GRAY: '#E6E7E9',
    BORDER_LIGHT_GRAY: '#D6D7DB',
    BLACK: '#000000',
};
export const opacityHex = {
    ten: '1A',
    thirty: '4D',
    sixty: '99',
};
export const appValues = {
    HEADER_HEIGHT_SMALL: 80,
    HEADER_HEIGHT: 100,
    FOOTER_HEIGHT: 45,
    SIDEBAR_WIDTH_FULL: 327,
    SIDEBAR_WIDTH_PREVIEW: 327 * 0.8,
    SIDEBAR_WIDTH_SMALL: 77,
    TRANSITION_DURATION: '0.3s',
};
export const logoSupportedFormats = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.svg',
    '.tiff',
    '.tif',
];
