import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { isLocalAdminPlus } from '@frontend/domain/contexts/Authorization/Roles';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import React from 'react';
import { AppLoading } from '../Loading/AppLoading';
export function RequireOrgAdminAccess({ unauthorizedPage, children, }) {
    const { user, status } = useCurrentUser();
    const { selectedOrganizationIdentity, isLoading: isOrgListLoading } = useOrganizationSummaries();
    const isOrgAdmin = isLocalAdminPlus(user, selectedOrganizationIdentity);
    const isLoadingInfo = isOrgListLoading || status.isLoading;
    return (_jsx(_Fragment, { children: isLoadingInfo ? (_jsx(AppLoading, {})) : !isOrgAdmin ? (unauthorizedPage) : (children) }));
}
