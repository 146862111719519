import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, GridItem, VStack } from '@chakra-ui/react';
import React from 'react';
import { Controller } from 'react-hook-form';
import { logoSupportedFormats } from '../../../config/constants';
import { OrganizationAppsSelector } from './OrganizationAppsSelector';
import { OrganizationInformation } from './OrganizationInformation';
import { OrganizationLogoInput } from './OrganizationLogoInput';
export const OrganizationFormInputs = ({ canEditVerticalMarket = true, //create mode
canEditOrgAccess = true, //create mode
canEditBusinessUnit, organization, organizationUseForm, editing, logoSmallRef, orgHeader, validateOrgName, users, logoName, verticalMarkets, }) => {
    const acceptedFileTypes = logoSupportedFormats.join(', ');
    const VALID_EXTENSIONS_ERROR_MESSAGE = `Allowed logo types: ${acceptedFileTypes}.`;
    const validFileSize = 3 * 1024 * 1024; // 3MB
    const VALID_SIZE_ERROR_MESSAGE = `Max logo size: ${validFileSize / 1024 / 1024} MB.`;
    const { control } = organizationUseForm;
    const handleImageErrorMessage = () => {
        var _a, _b;
        const input = logoSmallRef === null || logoSmallRef === void 0 ? void 0 : logoSmallRef.current;
        if (input) {
            const file = (_b = (_a = input.files) === null || _a === void 0 ? void 0 : _a.item(0)) !== null && _b !== void 0 ? _b : undefined;
            if (file) {
                const fileExtension = file.name
                    .substring(file.name.lastIndexOf('.'))
                    .toLowerCase();
                if (!logoSupportedFormats.includes(fileExtension)) {
                    return VALID_EXTENSIONS_ERROR_MESSAGE;
                }
                else if (file.size > validFileSize) {
                    return VALID_SIZE_ERROR_MESSAGE;
                }
                else {
                    return undefined;
                }
            }
        }
    };
    const editTemplateColumns = {
        base: '1fr 1fr',
        lg: '1fr 2fr 1fr',
        xl: '1fr 1.6fr 1.6fr',
    };
    const viewTemplateColumns = {
        base: 'repeat(2, auto)',
        lg: '1fr 1.6fr 1.6fr',
    };
    const editGridTemplateAreas = {
        base: `
			"logo orgDetails"
			"orgAccess orgAccess"
		`,
        lg: `
			 "logo orgDetails ${!canEditVerticalMarket ? 'orgDetails' : 'orgAccess'}"
		`,
        xl: `
			 "logo orgDetails ${!canEditVerticalMarket ? 'orgDetails' : 'orgAccess'}"
		`,
    };
    const viewGridTemplateAreas = {
        base: `
			"header header"
			"logo orgDetails"
			"orgAccess orgAccess"
		`,
        lg: `
        "logo header header"
        "logo orgDetails ${!canEditVerticalMarket ? 'orgDetails' : 'orgAccess'}"
    `,
    };
    return (_jsxs(Grid, { templateColumns: editing ? editTemplateColumns : viewTemplateColumns, templateRows: !editing ? 'repeat(2, auto)' : undefined, gridTemplateAreas: editing ? editGridTemplateAreas : viewGridTemplateAreas, gap: '40px', children: [!editing && (_jsx(GridItem, { gridArea: 'header', children: orgHeader && orgHeader() })), _jsx(GridItem, { gridArea: 'logo', children: _jsx(VStack, { w: 'fit-content', display: 'block', width: '100%', alignSelf: 'flex-start', children: _jsx(Controller, { name: LOGO_URL_KEY, control: control, rules: {
                            validate: () => handleImageErrorMessage(),
                        }, render: ({ field, fieldState: { error } }) => (_jsx(OrganizationLogoInput, { logoUrl: field.value, logoName: logoName, logoRef: logoSmallRef, imageError: error === null || error === void 0 ? void 0 : error.message, onLogoChange: field.onChange, editMode: editing, acceptedFileTypes: acceptedFileTypes })) }) }) }), _jsx(GridItem, { gridArea: 'orgDetails', children: _jsx(OrganizationInformation, { organization: organization, canEditVerticalMarket: canEditVerticalMarket, canEditBusinessUnit: canEditBusinessUnit, control: control, validateOrgName: validateOrgName, editMode: editing, users: users, verticalMarkets: verticalMarkets }) }), canEditVerticalMarket && (_jsx(GridItem, { gridArea: 'orgAccess', children: _jsx(Controller, { name: APPS_ACCESS_KEY, control: control, render: ({ field }) => (_jsx(OrganizationAppsSelector, { data: field.value, editMode: editing, canEdit: canEditOrgAccess, onChange: field.onChange })) }) }))] }));
};
const LOGO_URL_KEY = 'logoUrl';
const APPS_ACCESS_KEY = 'apps';
