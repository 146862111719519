import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Center, Text, VStack } from '@chakra-ui/react';
import { consolidateName } from '@frontend/domain/conversion/NameConversions';
export function UnauthorizedOrg({ organization }) {
    return (_jsx(Center, { h: '85vh', w: '100%', children: _jsxs(VStack, { gap: '2', children: [_jsx(Text, { children: "At this moment, you do not have access for this organization." }), _jsx(Text, { children: (organization === null || organization === void 0 ? void 0 : organization.primaryContact)
                        ? `Please contact your primary contact, ${consolidateName({
                            firstName: organization.primaryContact.firstName,
                            lastName: organization.primaryContact.lastName,
                        })} at ${organization.primaryContact.email}`
                        : 'Please contact your primary contact' })] }) }));
}
export function UnauthorizedMessage() {
    return (_jsx(Center, { h: '85vh', w: '100%', children: _jsx(VStack, { gap: '2', children: _jsx(Text, { children: "At this moment, you do not have the proper permission to see this page." }) }) }));
}
