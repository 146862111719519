import { IVerticalMarketNames, } from '@frontend/domain/models/Organization/OrganizationInterfaces';
// TODO: These are no longer
export var UserTypes;
(function (UserTypes) {
    UserTypes["admin"] = "admin";
    UserTypes["concierge"] = "concierge";
    UserTypes["customer"] = "customer";
    UserTypes["none"] = "none";
})(UserTypes || (UserTypes = {}));
export var OrganizationRoleEnum;
(function (OrganizationRoleEnum) {
    OrganizationRoleEnum["LOCAL_ADMIN"] = "LocalAdministrator";
    OrganizationRoleEnum["CLIENT_CONCIERGE"] = "ClientConcierge";
    OrganizationRoleEnum["MEMBER"] = "Member";
    OrganizationRoleEnum["VISITOR"] = "Visitor";
    OrganizationRoleEnum["NONE"] = "None";
})(OrganizationRoleEnum || (OrganizationRoleEnum = {}));
export var SystemRoleEnum;
(function (SystemRoleEnum) {
    SystemRoleEnum["SUPER_ADMIN"] = "SuperAdministrator";
    SystemRoleEnum["ORG_APPROVER"] = "OrganizationApprover";
    SystemRoleEnum["USER"] = "User";
})(SystemRoleEnum || (SystemRoleEnum = {}));
export const systemRolePriorityList = [
    SystemRoleEnum.SUPER_ADMIN,
    SystemRoleEnum.ORG_APPROVER,
    SystemRoleEnum.USER,
];
export var FacilityRoleEnum;
(function (FacilityRoleEnum) {
    FacilityRoleEnum["MEMBER"] = "Member";
    FacilityRoleEnum["VISITOR"] = "Visitor";
    FacilityRoleEnum["NONE"] = "None";
})(FacilityRoleEnum || (FacilityRoleEnum = {}));
export var ScenarioRolesEnum;
(function (ScenarioRolesEnum) {
    ScenarioRolesEnum["MEMBER"] = "Member";
    ScenarioRolesEnum["VISITOR"] = "Visitor";
})(ScenarioRolesEnum || (ScenarioRolesEnum = {}));
export const SystemRoles = [
    { role: 'System Admin', roleName: 'SuperAdministrator' },
    { role: 'Org Approver', roleName: 'OrganizationApprover' },
];
export var PropertySizeEnum;
(function (PropertySizeEnum) {
    PropertySizeEnum["keys"] = "# Keys";
    PropertySizeEnum["units"] = "# Units";
    PropertySizeEnum["sqrFootage"] = "Square Footage";
})(PropertySizeEnum || (PropertySizeEnum = {}));
export const VerticalMarketOptions = Object.values(IVerticalMarketNames);
export const BusinessUnitOptions = [
    'Midwest',
    'Northeast',
    'West',
    'C&I',
    'HQ',
    'Healthcare',
];
export var AppContext;
(function (AppContext) {
    AppContext["organize"] = "Organize";
    AppContext["prioritize"] = "Prioritize";
    AppContext["optimize"] = "Optimize";
})(AppContext || (AppContext = {}));
