var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { Center, VStack } from '@chakra-ui/react';
import '@frontend/design-system/theme/font';
import { organizationSessionStore } from '@frontend/domain/contexts/Organization/OrganizationSessionHandler';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { IAppNames } from '@frontend/domain/models/App';
import { startWorker } from '@frontend/domain/testing/msw/worker';
import { RequireAdminAccess } from '@frontend/shared/src/components/Access/RequireAdminAccess';
import { RequireOrgAdminAccess } from '@frontend/shared/src/components/Access/RequireOrgAdminAccess';
import { RequireOrgAppAccess } from '@frontend/shared/src/components/Access/RequireOrgAppAccess';
import { UnauthorizedMessage, UnauthorizedOrg, } from '@frontend/shared/src/components/Access/Unauthorized';
import { AppLoading } from '@frontend/shared/src/components/Loading/AppLoading';
import { ErrorPage } from '@frontend/shared/src/components/Routing/Error';
import { RequireWithinOrganization } from '@frontend/shared/src/components/Routing/RequireWithinOrganization';
import { AppPaths } from '@frontend/shared/src/components/Routing/RouteConstants';
import { UserControl } from '@frontend/shared/src/components/Routing/UserControl';
import React, { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import { authConfig } from './authConfig';
import { ReportsProvider } from './components/context/ReportsContext';
import { PageLayout } from './components/layouts/PageLayout';
import ActivatePage from './pages/ActivatePage';
import { LandingPage } from './pages/LandingPage';
import { LogoutPage } from './pages/LogoutPage';
import { CreateOrganization } from './pages/OrganizationDetails/CreateOrganization';
import { OrganizationDetailsPage } from './pages/OrganizationDetails/OrganizationDetailsPage';
import { UserProfilePage } from './pages/Profile/UserProfile/UserProfilePage';
import { ReportPage } from './pages/Report/ReportPage';
import { SupportPage } from './pages/SupportPage';
import { SystemAdminPage } from './pages/SystemAdmin/SystemAdminPage';
import { apiService, platformApiService } from './services';
import { handlers } from './utils/testing/Mocks/handlers';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
function App() {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        if (isAuthenticated && (user === null || user === void 0 ? void 0 : user.email)) {
            platformApiService.setTokenRetrievalFunction(() => __awaiter(this, void 0, void 0, function* () {
                return yield getAccessTokenSilently({
                    authorizationParams: {
                        audience: authConfig.authorizationParams.audience,
                        scope: 'email read:current_user update:current_user_metadata',
                    },
                });
            }));
            apiService.setTokenRetrievalFunction(() => __awaiter(this, void 0, void 0, function* () {
                return yield getAccessTokenSilently({
                    authorizationParams: {
                        audience: authConfig.authorizationParams.audience,
                        scope: 'email read:current_user update:current_user_metadata',
                    },
                });
            }));
        }
    }, [isAuthenticated, user]);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (process.env.REACT_APP_API_MOCKING === 'enabled') {
                startWorker(handlers); // msiq specific mocks added
            }
        }
    }, []);
    const ReportsUserControl = ({ children, }) => {
        return (_jsx(UserControl, { authorizationParams: authConfig.authorizationParams, children: _jsx(ReportsProvider, { children: children }) }));
    };
    const { organization, reports, support, logout, profile, admin, home, activate, } = AppPaths;
    return (_jsxs(Routes, { children: [_jsx(Route, { path: logout, element: _jsx(LogoutPage, {}) }), _jsx(Route, { path: activate, element: _jsx(ActivatePage, {}) }), _jsxs(Route, { path: home, element: _jsx(ReportsUserControl, { children: _jsx(Outlet, {}) }), errorElement: _jsx(ErrorPage, {}), children: [_jsx(Route, { path: home, element: _jsx(Navigate, { to: organization.base }) }), _jsxs(Route, { element: _jsx(RequireOrgSelectedOnNonOrgPath, {}), children: [_jsx(Route, { path: profile, element: _jsx(UserProfilePage, {}) }), _jsx(Route, { path: support, element: _jsx(SupportPage, {}) }), _jsxs(Route, { path: admin.base, element: _jsx(RequireAdminAccess, { unauthorizedPage: _jsx(UnauthorizedMessagePage, {}), children: _jsx(Outlet, {}) }), children: [_jsx(Route, { path: admin.base, element: _jsx(SystemAdminPage, {}) }), _jsx(Route, { path: admin.createOrganization, element: _jsx(CreateOrganization, {}) })] })] }), _jsx(Route, { path: organization.base, element: _jsx(RequireWithinOrganization, { basePath: organization.base, children: _jsx(Outlet, {}) }), children: _jsxs(Route, { path: organization.withOrgId(), element: _jsx(Outlet, {}), children: [_jsx(Route, { path: organization.withOrgId(), element: _jsx(LandingPage, {}) }), _jsx(Route, { path: organization.details(), element: _jsx(RequireOrgAdminAccess, { unauthorizedPage: _jsx(UnauthorizedMessagePage, {}), children: _jsx(OrganizationDetailsPage, {}) }) }), _jsx(Route, { path: reports.withReportId(), element: _jsx(RequireMsiqOrgAppAccess, { children: _jsx(ReportPage, {}) }) })] }) })] })] }));
}
const RequireOrgSelectedOnNonOrgPath = () => {
    const { organizations, isLoading, selectedOrganizationIdentity, onSelectOrganization, } = useOrganizationSummaries();
    const { getSessionOrgIdentity } = organizationSessionStore();
    const isInSummaryList = (orgIdentity) => {
        const foundIndex = organizations.findIndex((org) => org.identity === orgIdentity);
        return foundIndex >= 0;
    };
    useEffect(() => {
        if (!isLoading && organizations.length > 0) {
            const sessionOrgIdentity = getSessionOrgIdentity();
            if (sessionOrgIdentity && isInSummaryList(sessionOrgIdentity)) {
                onSelectOrganization(sessionOrgIdentity);
            }
            else {
                onSelectOrganization(organizations[0].identity);
            }
        }
    }, [isLoading, organizations]);
    return selectedOrganizationIdentity ? _jsx(Outlet, {}) : _jsx(AppLoading, {});
};
const UnauthorizedMessagePage = () => {
    return (_jsx(PageLayout, { children: _jsx(Center, { h: '100vh', children: _jsx(VStack, { children: _jsx(UnauthorizedMessage, {}) }) }) }));
};
const RequireMsiqOrgAppAccess = ({ children, }) => {
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const { organizationDetails } = useOrganization({
        organizationIdentity: selectedOrganizationIdentity,
    });
    return (_jsx(RequireOrgAppAccess, { requiredApp: IAppNames.REPORTS, unauthorizedPage: _jsx(PageLayout, { children: _jsx(Center, { h: '100vh', children: _jsx(VStack, { children: _jsx(UnauthorizedOrg, { organization: organizationDetails }) }) }) }), children: children }));
};
export default App;
