import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormLabel, HStack, Stack, Text } from '@chakra-ui/react';
import { isClientConciergePlus } from '@frontend/domain/contexts/Authorization/Roles';
import React, { useEffect, useState } from 'react';
import { SelectInputNoRegister } from '../../../components/forms/SelectInputNoRegister';
import { appColors, opacityHex } from '../../../config/constants';
import { OrganizationRoleEnum, } from '../../../objects/UserData';
import { usePlatformOrgApi } from '../../../services/api/platformOrgApiHook';
import { toOrgRoleString } from '../../../utils/userRoleUtils';
import AddAppsToUser from './AddAppsToUser';
const getOrgsAssigned = (orgAdjustments, availableOrgs) => {
    const availableOrgsById = availableOrgs.reduce((orgsById, org) => {
        if (org.identity) {
            orgsById.set(org.identity, org);
        }
        return orgsById;
    }, new Map());
    return orgAdjustments
        .filter((orgAdj) => orgAdj.organizationIdentity)
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((orgAdj) => 
    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    availableOrgsById.get(orgAdj.organizationIdentity))
        .filter((org) => !!org);
};
export const AddOrgToUser = ({ originalUserOrgRoles: originalUserOrgRoles, updateOrgRoles, originalUserFacilityRoles: currentUserFacilityRoles, updateFacRoles, orgUserApps, setOrgUserApps, availableOrgs, currentUser, }) => {
    const { getPlatformSitesByOrgId } = usePlatformOrgApi();
    const orgRoleOptions = [
        OrganizationRoleEnum.MEMBER,
        OrganizationRoleEnum.VISITOR,
    ];
    const orgRoleOptionsWithLa = [
        OrganizationRoleEnum.LOCAL_ADMIN,
        OrganizationRoleEnum.MEMBER,
        OrganizationRoleEnum.VISITOR,
    ];
    const orgRoleOptionsWithCc = [
        OrganizationRoleEnum.LOCAL_ADMIN,
        OrganizationRoleEnum.CLIENT_CONCIERGE,
        OrganizationRoleEnum.MEMBER,
        OrganizationRoleEnum.VISITOR,
    ];
    const [selectedOrg, setSelectedOrg] = useState();
    const [selectedOrgFacilities, setSelectedOrgFacilities] = useState([]);
    // should get refactored as part of user mgmt changes
    const hasCcAccess = isClientConciergePlus(currentUser, selectedOrg === null || selectedOrg === void 0 ? void 0 : selectedOrg.identity);
    const orgsAssigned = !availableOrgs
        ? []
        : getOrgsAssigned(originalUserOrgRoles, availableOrgs);
    const canSelectNewOrg = !selectedOrg ||
        (!!originalUserOrgRoles.find((currentUserOrgRoles) => currentUserOrgRoles.organizationIdentity ===
            selectedOrg.identity) &&
            orgsAssigned.length < availableOrgs.length);
    const selectedOrgRole = selectedOrg
        ? originalUserOrgRoles.find((orgAdj) => orgAdj.organizationIdentity === selectedOrg.identity)
        : undefined;
    useEffect(() => {
        if (selectedOrg) {
            getPlatformSitesByOrgId(selectedOrg.identity).then((sites) => {
                if (sites.length > 0) {
                    const allFacilities = sites.flatMap((site) => site.facilities.map((facility) => {
                        return {
                            facilityIdentity: facility.facilityIdentity,
                            name: facility.name,
                        };
                    }));
                    setSelectedOrgFacilities(allFacilities);
                }
            });
        }
    }, [selectedOrg]);
    const selectedOrgFacilityIds = new Set(selectedOrgFacilities.map((facility) => facility.facilityIdentity));
    useEffect(() => {
        if (availableOrgs.length === 1 && !selectedOrg) {
            setSelectedOrg(availableOrgs[0]);
        }
    }, [availableOrgs, selectedOrg, setSelectedOrg]);
    const onSelectOrg = (orgIdentityString) => {
        if (NONE_OPTION === orgIdentityString) {
            setSelectedOrg(undefined);
            return;
        }
        const org = availableOrgs &&
            availableOrgs.find((org) => { var _a; return orgIdentityString === ((_a = org.identity) === null || _a === void 0 ? void 0 : _a.toString()); });
        setSelectedOrg(org);
    };
    const onSelectOrgRole = (orgRole, selectedOrg) => {
        updateOrgRoles({
            organizationIdentity: selectedOrg.identity,
            role: orgRole,
            unassign: false,
        });
    };
    const handleOrgAppsChanged = (orgApps) => {
        if (selectedOrg) {
            setOrgUserApps([
                ...orgUserApps.filter((orgUserApp) => selectedOrg.identity !== orgUserApp.organizationIdentity),
                ...orgApps,
            ]);
        }
    };
    const orgItem = () => {
        return (_jsx(_Fragment, { children: orgsAssigned &&
                orgsAssigned.map((org) => {
                    var _a;
                    return (_jsx(Box, { bg: org.identity === (selectedOrg === null || selectedOrg === void 0 ? void 0 : selectedOrg.identity)
                            ? `${appColors.SEC_ORANGE}${opacityHex.ten}`
                            : '', onClick: () => setSelectedOrg(org), px: '13px', py: '10px', children: _jsx(Text, { children: org.name }) }, `org item ${(_a = org.identity) === null || _a === void 0 ? void 0 : _a.toString()}`));
                }) }));
    };
    const renderLeftContent = () => {
        var _a;
        return (_jsxs(Stack, { w: '100%', children: [_jsxs(FormLabel, { children: ["Organization ", _jsx("span", { className: 'required', children: " *" })] }), _jsx(Stack, { children: orgItem() }), availableOrgs && (_jsx(SelectInputNoRegister, { defaultValue: '', value: ((_a = selectedOrg === null || selectedOrg === void 0 ? void 0 : selectedOrg.identity) === null || _a === void 0 ? void 0 : _a.toString()) || NONE_OPTION, onChange: (e) => onSelectOrg(e.target.value), id: 'org-select', required: true, disabled: !canSelectNewOrg, children: _jsx(_Fragment, { children: availableOrgs
                            .filter((org) => !orgsAssigned.find((orgAssigned) => orgAssigned.identity ===
                            org.identity))
                            .map((org) => {
                            var _a, _b;
                            return (_jsx("option", { value: (_a = org.identity) === null || _a === void 0 ? void 0 : _a.toString(), children: org.name }, `org option ${(_b = org.identity) === null || _b === void 0 ? void 0 : _b.toString()}`));
                        }) }) }))] }));
    };
    const renderOrgRoleOptions = (selectedOrgRole) => {
        if (selectedOrgRole === OrganizationRoleEnum.CLIENT_CONCIERGE ||
            hasCcAccess) {
            return orgRoleOptionsWithCc.map((role) => {
                return (_jsx("option", { value: role, children: toOrgRoleString(role) }, role));
            });
        }
        else {
            if (selectedOrgRole === OrganizationRoleEnum.LOCAL_ADMIN ||
                hasCcAccess) {
                return orgRoleOptionsWithLa.map((role) => {
                    return (_jsx("option", { value: role, children: toOrgRoleString(role) }, role));
                });
            }
            else {
                return orgRoleOptions.map((role) => {
                    return (_jsx("option", { value: role, children: toOrgRoleString(role) }, role));
                });
            }
        }
    };
    const renderRightContent = () => {
        var _a;
        return (_jsx(Box, { bg: appColors.LIGHT_GRAY_1, h: '100%', w: '100%', children: selectedOrg && (_jsxs(_Fragment, { children: [_jsx(Stack, { h: '100%', children: _jsx(SelectInputNoRegister, { label: 'Organization Role', defaultValue: '' // TODO: should not need if passing in value as well
                            , value: (selectedOrgRole === null || selectedOrgRole === void 0 ? void 0 : selectedOrgRole.role) || '', required: true, onChange: (e) => onSelectOrgRole(e.target.value, selectedOrg), id: 'org-role-select', disabled: ((selectedOrgRole === null || selectedOrgRole === void 0 ? void 0 : selectedOrgRole.role) ===
                                OrganizationRoleEnum.CLIENT_CONCIERGE &&
                                !hasCcAccess) ||
                                ((selectedOrgRole === null || selectedOrgRole === void 0 ? void 0 : selectedOrgRole.role) ===
                                    OrganizationRoleEnum.LOCAL_ADMIN &&
                                    !hasCcAccess), children: _jsx(_Fragment, { children: renderOrgRoleOptions((selectedOrgRole === null || selectedOrgRole === void 0 ? void 0 : selectedOrgRole.role) ||
                                    OrganizationRoleEnum.NONE) }) }) }), (selectedOrgRole === null || selectedOrgRole === void 0 ? void 0 : selectedOrgRole.role) !==
                        OrganizationRoleEnum.LOCAL_ADMIN &&
                        (selectedOrgRole === null || selectedOrgRole === void 0 ? void 0 : selectedOrgRole.role) !==
                            OrganizationRoleEnum.CLIENT_CONCIERGE &&
                        selectedOrg.apps &&
                        selectedOrg.apps.length > 0 && (_jsx(AddAppsToUser, { org: selectedOrg, orgUserApps: orgUserApps.filter((orgUserApp) => selectedOrg.identity ===
                            orgUserApp.organizationIdentity), userOrgRole: (_a = selectedOrgRole === null || selectedOrgRole === void 0 ? void 0 : selectedOrgRole.role) !== null && _a !== void 0 ? _a : OrganizationRoleEnum.NONE, onOrgUserAppsChanged: handleOrgAppsChanged, selectedOrgFacilities: selectedOrgFacilities, selectedOrgFacilityIds: selectedOrgFacilityIds, currentUserFacilityRoles: currentUserFacilityRoles, updateFacRoles: updateFacRoles }))] })) }));
    };
    return (_jsx(HStack, { alignItems: 'flex-start', justifyContent: 'space-between', p: '10px', w: '100%', children: _jsxs(_Fragment, { children: [renderLeftContent(), renderRightContent()] }) }));
};
const NONE_OPTION = 'NONE_OPTION';
